
import { getCustomerPortalSessionUrl } from "@/api/stripe";
import { updateSchoolPaymentMethodToStripe } from "@/api/school";
import MButton from "@/components/MButton.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MButton
  }
})
export default class Payment extends Vue {
  async redirectCustomerPortal() {
    if (!store.state.school) {
      return;
    }

    store.commit("START_LOADING", "");
    const schoolId = store.state.school.ref.id;
    try {
      const res = await Promise.all([
        getCustomerPortalSessionUrl(store.state.school.ref.id),
        updateSchoolPaymentMethodToStripe(schoolId)
      ]);
      const url = res[0];
      store.commit("END_LOADING");
      window.location.href = url;
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get customer portal session url"
      );
    }
  }
}
